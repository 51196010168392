// Scripts
import './src/index';

// Styles
import './src/index.css';
import './src/styles/custom.scss';  // Imported in other modules
import './assets/scss/main.scss';

// Images
import './src/images/badge.svg';  // Currently not used
import './src/images/mobile-countries-dropdown.png';  // Currently not used
import './src/images/garbage.svg';
import './src/images/edit_icon.svg';
import './src/images/check_box.svg';
import './src/images/check_box_full.svg';
import './src/images/close.svg';
import './src/images/main-background.jpg';
import './src/images/search.svg';
import './src/images/tooltag-add.svg';

// Fonts
import './src/font/Exo-Black.woff';
import './src/font/Exo-Black.woff2';
import './src/font/Exo-Bold.woff';
import './src/font/Exo-Bold.woff2';
import './src/font/Exo-ExtraLight.woff';
import './src/font/Exo-ExtraLight.woff2';
import './src/font/Exo-Light.woff';
import './src/font/Exo-Light.woff2';
import './src/font/Exo-LightItalic.woff';
import './src/font/Exo-LightItalic.woff2';
import './src/font/Exo-Medium.woff';
import './src/font/Exo-Medium.woff2';
import './src/font/Exo-Regular.woff';
import './src/font/Exo-Regular.woff2';
import './src/font/Exo-SemiBold.woff';
import './src/font/Exo-SemiBold.woff2';
